:root {
  --blue: rgb(30, 97, 241);
  --grey: lightgray;
}

@font-face {
  src: url('./assets/fonts/Poppins.ttf');
  font-family: Poppins;
}

@font-face {
  src: url('./assets/fonts/Rubik.ttf');
  font-family: Rubik;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Poppins, Rubik;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  width: 100%;
  height: 100%;
  padding: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.flex-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.flex-col {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.flex-row-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flex-col-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.align-center {
  align-items: center;
}

.page {
  width: 100%;
  min-height: calc(100vh - 4rem - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
}
.page > * {
  max-width: 90%;
}

.page-head {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.page-head > h2 > span {
  color: var(--grey);
}

.text-center {
  text-align: center;
}
.small-grey-text {
  color: grey;
  font-size: 0.8rem;
}
.smaller-grey-text {
  color: grey;
  font-size: 0.7rem;
}

.grey-border {
  margin: 0.5rem auto;
  padding: 0.5rem 1rem;
  border: 1px solid var(--grey);
  border-radius: 7px;
}

.grey-shadow {
  box-shadow: 0px 0px 5px 2px var(--grey);
}
.blue-shadow {
  box-shadow: 0px 0px 5px 0px var(--blue);
}
.blue-shadow-hover:hover,
.blue-shadow-hover:active {
  box-shadow: 0px 0px 5px 0px var(--blue);
}

.avatar {
  width: 42px;
  height: 42px;
  margin-right: 0.5rem;
  border-radius: 100%;
}

.data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
}
.data-table th {
  padding: 0 0.5rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: normal;
  color: grey;
}
.data-table > tbody > tr {
  border-radius: 0.5rem;
  box-shadow: 0px 1px 4px 1px var(--grey);
}
.data-table td {
  padding: 1rem 0.5rem;
}
.data-table p {
  min-width: 120px;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.consent-table {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 1rem;
}
.consent-table > tbody > tr > td:nth-child(1) {
  text-align: left;
  color: grey;
}
.consent-table > tbody > tr > td:nth-last-child(1) {
  text-align: right;
}
.consent-table > tbody > tr > td:nth-last-child(1) span {
  color: grey;
  font-size: 0.9rem;
}
